import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import {sluger} from "../util/sluger"

const PostCardHome = ({ data }) => (
    <article className="post-card-home">
      <div className="container">
        {data.frontmatter.featuredImage ? (
             <div className="image-box">
          <Link to={data.frontmatter.slug ? data.frontmatter.slug : sluger(data.frontmatter.title,"/blog/") }><Img
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + " - Featured image"}
            className="featured-image"
          />
          </Link>
          </div>
        ) : (
          ""
        )}
      <div className="spacer" />
      <div className="post-content">
        <h2 className="title"><Link to={data.frontmatter.slug ? data.frontmatter.slug : sluger(data.frontmatter.title,"/blog/") }>{data.frontmatter.title}</Link></h2>
        <p className="meta">
          <time>{data.frontmatter.date}</time>
        </p>
        <Link to={data.frontmatter.slug ? data.frontmatter.slug : sluger(data.frontmatter.title,"/blog/") }><p className="description">{data.excerpt}</p></Link>
      </div>
      </div>
    </article>
)

export default PostCardHome
