import React from "react"
import { graphql, Link } from "gatsby"
import { RiArrowDownLine, RiArrowRightSLine } from "react-icons/ri"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCardHome from "../components/post-card-home"
//import webBanner from "../../static/assets/web_banner.jpg"

const PostMaker = ({ data }) => (
  <section className="home-posts">
    <h2>
      Latest <strong>Updates</strong>
      <span className="icon -right">
        <RiArrowDownLine />
      </span>
    </h2>
    <div> {data}</div>
    <div className="vertical-center">
      <Link className="button-block" to="/blog">
        See more
        <span className="icon -right">
          <RiArrowRightSLine />
        </span>
      </Link>
    </div>
  </section>
)

export const pageQuery = graphql`
  query HomeQuery {
    blogList: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } ,date: {ne: null } } }
      limit: 6
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            slug
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 464, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const posts = data.blogList.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostCardHome key={edge.node.id} data={edge.node} />)
  return (
    <Layout>
      <SEO/>
      <PostMaker data={posts} />
    </Layout>
  )
}

export default HomePage
